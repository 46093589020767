<template>

  <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100">
    <div class="rounded-2xl w-full overflow-x-auto bg-white">
      <!-- Barra de pasos -->
      <div class="flex justify-between items-center border-b mx-6 border-gray-300 px-20 py-3 text-sm">
        <div class="flex items-center">
          <p :class="{ 'text-blue-500 font-semibold': currentStep === 1, 'text-gray-400': currentStep !== 1 }"
            class="text-blue-500">1. Cargar firmantes</p>
        </div>
        <div class="flex items-center">
          <p :class="{ 'text-blue-500 font-semibold': currentStep === 2, 'text-gray-400': currentStep !== 2 }"
            class="text-blue-500">2. Cargar documento</p>
        </div>
        <div class="flex items-center">
          <p :class="{ 'text-blue-500 font-semibold': currentStep === 3, 'text-gray-400': currentStep !== 3 }"
            class="text-blue-500">3. Enviar</p>
        </div>
      </div>

      <!-- Paso 1: Cargar firmantes -->
      <div v-if="currentStep === 1">
        <div class="flex justify-end p-6">
          <div class="flex items-center space-x-4 mr-6">
            <button class="text-gray-500 hover:text-gray-400 font-bold flex items-center space-x-2 cursor-not-allowed">
              <i
                class="fas fa-arrow-left mr-2 text-white bg-gray-500 hover:bg-gray-400 rounded-full p-1"></i>Volver</button>
            <button @click="nextStep" class="text-blue-500 hover:text-blue-400 font-bold">Continuar<i
                class="fas fa-arrow-right ml-2 text-white bg-blue-500 hover:bg-blue-400 rounded-full p-1"></i></button>
          </div>
        </div>

        <!-- MENSAJE DE ERROR -->
        <div v-if="signerError"
          class="m-6 mb-0 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
          style="width: 460px;">
          <span class="ml-2 font-bold">{{ signerErrorMessage }}</span>
          <button @click="signerError = false"><i
              class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
        </div>

        <!-- Campos para los firmantes -->
        <div class="m-6">
          <div v-for="(signer, index) in signers" :key="index" class="mb-12">
            <div class="flex items-center">
              <input type="text" v-model="signer.name"
                :placeholder="index === 0 ? 'Nombre firmante' : 'Nombre firmante ' + (index + 1)"
                class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-12">
              <input type="text" v-model="signer.lastName"
                :placeholder="index === 0 ? 'Apellido firmante' : 'Apellido firmante ' + (index + 1)"
                class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-12">
              <input type="text" v-model="signer.dni"
                :placeholder="index === 0 ? 'DNI / CI firmante' : 'DNI / CI firmante ' + (index + 1)"
                class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800"
                @input="sanitizeDNI(index)">
            </div>
            <div class="flex items-center">
              <select v-model="signer.contact" class="p-2 mr-10 w-36 rounded border border-gray-300">
                <option value="wpp" selected>WhatsApp</option>
              </select>

              <template v-if="signer.contact === 'wpp'">
                <select v-model="signer.areaCode" placeholder="Área"
                  class="border-gray-300 border-b-2 w-35 focus:outline-none p-2 pl-0 mr-6 placeholder-gray-800">
                  <option value="549" selected>🇦🇷 +549</option>
                  <option value="34">🇪🇸 +34</option>
                  <option value="56">🇨🇱 +56</option>
                  <option value="598">🇺🇾 +598</option>
                  <option value="521">🇲🇽 +521</option>
                  <option value="593">🇪🇨 +593</option>
                  <option value="591">🇧🇴 +591</option>
                  <option value="57">🇨🇴 +57</option>
                  <option value="51">🇵🇪 +51</option>
                  <option value="1">🇺🇸 +1</option>
                  <option value="502">🇩🇴 +502</option>
                  <option value="595">🇵🇾 +595</option>
                </select>
                <input type="text" v-model="signer.phoneNumber" placeholder="Teléfono" @input="sanitizePhoneNumber"
                  class="border-gray-300 border-b-2 w-56 focus:outline-none p-2 placeholder-gray-800">
              </template>
              <div v-show="signers.length > 1" class="flex flex-col">
                <button @click="removeSigner(index)" class="flex items-center ml-8">
                  <i class="fas fa-minus-circle text-red-500 mr-2 text-xl"></i>
                  <span class="text-gray-500 hover:text-gray-400">Eliminar firmante</span>
                </button>
              </div>
            </div>
          </div>

          <!-- MENSAJE DE ERROR -->
          <div v-if="maxSignersError"
            class="mb-12 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
            style="width: 460px;">
            <span class="ml-2 font-bold">{{ maxSignersErrorMessage }}</span>
            <button @click="maxSignersError = false"><i
                class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
          </div>

          <button @click="addSigner"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full"><i
              class="fas fa-search-plus mr-2"></i>Agregar firmante</button>
        </div>
      </div>

      <!-- Paso 2: Cargar documento -->
      <div v-if="currentStep === 2" class="container mx-auto p-6 space-y-6">

        <!-- Document ID Input and Buttons -->
        <div class="flex items-center justify-between">
          <!-- Document ID Input -->
          <div class="ml-6 flex items-center text-lg">
            <input type="text" v-model="documentId"
              class="border-b border-gray-300 mr-4 placeholder-black focus:outline-none w-64"
              placeholder="Id / Nombre del documento" />
            <i class="fas fa-edit text-gray-400"></i>
          </div>

          <!-- Navigation Buttons -->
          <div class="flex items-center space-x-4 mr-6">
            <button @click="previousStep"
              class="text-blue-500 hover:text-blue-400 font-bold flex items-center space-x-2" :disabled="loading">
              <i class="fas fa-arrow-left text-white bg-blue-500 hover:bg-blue-400 rounded-full p-1"></i>
              <span>Volver</span>
            </button>
            <button @click="nextStep" :class="[
              'font-bold flex items-center space-x-2',
              { 'text-blue-500 hover:text-blue-400': isFileUploaded && isPdfLoaded && !loading, 'text-gray-400 cursor-not-allowed': loading || !isFileUploaded || !isPdfLoaded }
            ]" :disabled="loading || !isFileUploaded || !isPdfLoaded">
              <span>Continuar</span>
              <i :class="{
                'fas fa-arrow-right ml-2 rounded-full p-1': true,
                'text-white bg-blue-500 hover:bg-blue-400': isFileUploaded && isPdfLoaded && !loading,
                'bg-gray-500': !isFileUploaded || !isPdfLoaded || loading
              }"></i>
            </button>

          </div>
        </div>

        <!-- Document ID Error Message -->
        <div v-if="documentIdError"
          class="m-6 mb-0 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center w-96">
          <span class="font-bold">{{ documentIdErrorMessage }}</span>
          <button @click="removeErrorMessage('documentIdError')" class="text-gray-800 hover:bg-red-300 px-2 rounded-sm">
            <i class="fas fa-times text-lg cursor-pointer"></i>
          </button>
        </div>

        <div>
          <!-- Signature Position Selection -->
          <ul class="ml-6 space-y-4 space-x-2">
            <li class="flex items-center mb-2">
              <p>Posición de firma:</p>
              <div class="flex items-center">
                <select v-model="selectedCampainName"
                  class="border-gray-300 border-b-2 w-35 focus:outline-none ml-2 placeholder-gray-800">
                  <option v-for="item in campains" :key="item.id" :value="item.name">{{ item.name }}</option>
                </select>
                <i @mouseover="showInfoMessage2 = true" @mouseleave="showInfoMessage2 = false"
                  class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer ml-2"></i>

                <!-- Info Tooltip -->
                <div v-if="showInfoMessage2"
                  class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 text-gray-700 shadow-lg z-10">
                  <p>Si tienes documentos recurrentes con otra ubicación específica de firma, simplemente envía un
                    correo
                    a soporte@wapifirma.com con el documento en PDF y la posición exacta donde te gustaría que
                    coloquemos
                    la firma para agregarla a tu desplegable.</p>
                </div>
              </div>
            </li>
          </ul>

          <!-- Pedir fotos -->
          <div class="flex items-center space-x-2 ml-6">
            <input type="checkbox" v-model="send_dni" class="cursor-pointer h-4 w-4">
            <p>Pedir foto de DNI/CI + foto Selfie</p>
            <i @mouseover="showInfoMessage = true" @mouseleave="showInfoMessage = false"
              class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"></i>

            <!-- Info Tooltip -->
            <div v-if="showInfoMessage"
              class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 text-gray-700 shadow-lg z-10">
              <p>El firmante deberá tomar una foto del documento de identidad + foto selfie para firmar (Wapi no
                valida
                las fotos). Las fotos quedarán en el informe de firmas.</p>
            </div>
          </div>

          <!-- Validar DNI/CI Checkbox -->
          <div v-if="selectedCampaignId === 1" class="flex items-center space-x-2 ml-6">
            <input type="checkbox" v-model="validate_dni" class="cursor-pointer h-4 w-4">
            <p>Validar DNI/CI</p>
            <i @mouseover="showInfoMessageValidacion = true" @mouseleave="showInfoMessageValidacion = false"
              class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"></i>

            <!-- Info Tooltip -->
            <div v-if="showInfoMessageValidacion"
              class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 text-gray-700 shadow-lg z-10">
              <p>Requiere Foto de DNI/CI para validación del Firmante</p>
            </div>
          </div>

          <!-- Firmar en todas las hojas -->
          <div v-if="selectedCampaignId !== 0" class="flex items-center space-x-2 ml-6">
            <input type="checkbox" v-model="todas_las_hojas" class="cursor-pointer h-4 w-4">
            <p>Firma en todas las hojas</p>
            <i @mouseover="showInfoMessageHojas = true" @mouseleave="showInfoMessageHojas = false"
              class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"></i>

            <!-- Info Tooltip -->
            <div v-if="showInfoMessageHojas"
              class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 text-gray-700 shadow-lg z-10">
              <p>Se aplica la firma en todas las hojas.</p>
            </div>
          </div>


          <!-- Container for Files and PDF Viewer -->
          <div class="m-6 flex space-x-6">
            <!-- Display Uploaded Files -->
            <div v-if="fileNames.length > 0" class="space-y-2">
              <div v-for="(name, index) in fileNames" :key="index"
                class="bg-blue-100 p-2 rounded-md text-center flex justify-between items-center w-96">
                <span class="text-black">{{ index + 1 }}. {{ name }}</span>
                <button @click="removeFile(index)" class="text-gray-800 hover:bg-blue-300 px-2 rounded-sm">
                  <i class="fas fa-times text-lg cursor-pointer"></i>
                </button>
              </div>
            </div>

            <!-- PDF Viewer without horizontal scroll -->
            <div v-if="base64Doc && selectedCampaignId === 1" class="flex overflow-hidden p-7">
              <PdfViewer :pdfData="base64Doc" :signers="signersData" @signers-load="onPdfLoaded"
                @signers-not-load="onPdfNotLoaded" @coords="onCoords" />
            </div>
          </div>

        </div>

        <!-- Loading Message -->
        <div v-if="loading" class="m-6 text-center">
          <div class="inline-block w-24 h-1 bg-blue-500 rounded-full animate-pulse"></div>
          <p class="mt-2 text-gray-500">Cargando...</p>
        </div>

        <!-- Error Message -->
        <div v-if="errorMessage"
          class="m-6 text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center w-96">
          <span class="font-bold">{{ errorMessage }}</span>
          <button @click="removeErrorMessage('errorMessage')" class="text-gray-800 hover:bg-red-300 px-2 rounded-sm">
            <i class="fas fa-times text-lg cursor-pointer"></i>
          </button>
        </div>

        <!-- File Upload -->
        <div v-if="!isFileUploaded" class="m-6 flex justify-center">
          <label for="fileInput"
            class="w-96 h-60 flex flex-col items-center text-center px-4 py-6 hover:bg-blue-100 cursor-pointer border-4 border-blue-400 border-dashed"
            @dragover.prevent @drop.prevent="handleDrop">
            <i class="fas fa-file-pdf text-red-500 fa-4x mb-4"></i>

            <p class="text-gray-400">Arrastrar y soltar el documento .pdf aquí o:</p>
            <button @click="openFileInput"
              class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full mt-2">
              <i class="fas fa-search-plus mr-2"></i>Seleccionar archivo
            </button>
            <input id="fileInput" type="file" accept=".pdf" class="hidden" @change="handleFileChange" multiple />
          </label>
        </div>



      </div>


      <!-- Paso 3: Enviar -->
      <div v-if="currentStep === 3">

        <div class="m-6">
          <div v-for="(signer, index) in todasLasSolicitudes.urls" :key="'signer-' + index">
            <div class="flex items-center">
              <input type="text" v-model="signer.name"
                class="border-b-2 border-gray-300 p-2 pl-0 mr-6 mb-6 placeholder-gray-800 focus:outline-none w-56"
                placeholder="Nombre">
              <input v-if="signer.method === 'wpp'" type="number" v-model="signer.contact"
                class="border-b-2 border-gray-300 p-2 pl-0 mr-6 mb-6 placeholder-gray-800 focus:outline-none w-56"
                placeholder="Número">
              <input v-else type="text" v-model="signer.contact"
                class="border-b-2 border-gray-300 p-2 pl-0 mr-6 mb-6 placeholder-gray-800 focus:outline-none w-56"
                placeholder="Correo">
            </div>

            <div class="flex items-center justify-start mt-4 mb-8">
              <a :href="signer.link" target="_blank"
                class="bg-gray-200 p-2 rounded-xl text-blue-600 hover:text-blue-500 hover:underline text-lg mr-8">
                {{ signer.link }}
                <span class="ml-10">
                  <i v-if="!copiedLinks.includes(signer.link)" @click.prevent="copyLink(signer.link)"
                    class="fas fa-clone ml-2 mr-1 text-blue-400 hover:text-blue-300 cursor-pointer"></i>
                  <i v-else class="fas fa-check-circle ml-2 mr-1 text-green-400"></i>
                </span>
              </a>
              <div class="flex items-center">
                <i v-if="signer.method === 'mail'" class="fas fa-envelope text-xl mr-2 text-gray-500"></i>
                <i v-else-if="signer.method === 'wpp'" class="fab fa-whatsapp text-xl mr-2 text-gray-500"></i>
                <input type="checkbox" v-model="signer.sendWithWapiIA" class="mr-2 ml-0.5 cursor-pointer h-4 w-4"
                  @change="handleCheckboxChange(signer)">
                <p>Enviar con WapIA</p>
                <i @mouseover="showInfoMessage = true" @mouseleave="showInfoMessage = false"
                  class="fas fa-info-circle text-xl ml-2 text-gray-500 hover:text-gray-400 cursor-pointer"></i>
                <div v-if="showInfoMessage === true"
                  class="absolute bg-gray-200 rounded-lg text-sm py-1 px-6 w-1/5 top-1/5 text-gray-700 shadow-lg z-10 mr-4 mt-32">
                  <p>Envía el documento automáticamente mediante nuestro asistente de WhatsApp WapIA</p>
                </div>
              </div>
            </div>
          </div>


          <div class="flex justify-center">
            <button @click="updateData"
              :class="[{ 'bg-blue-500 hover:bg-blue-600': hasChanges, 'bg-gray-500 hover:bg-gray-600': !hasChanges }, 'text-white', 'px-4', 'py-1', 'font-bold', 'rounded-full', 'mr-4']">
              <i class="fas fa-pen mr-2"></i>Actualizar datos
            </button>
            <router-link to="/requests?enviado=true">
              <button @click="sendDocuments"
                class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full">
                <i class="fas fa-file mr-2"></i>Enviar documento
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>

  <PopUpComponent :show="showPopUp" :message="PopUpMessage" />
  <ModalPurchaseComponent v-if="showLimitExceededModal" :show="showLimitExceededModal"
    @close="showLimitExceededModal = false" />
</template>

<script>
import PopUpComponent from '../components/PopUpComponent.vue';
import ModalPurchaseComponent from '../components/ModalPurchaseComponent.vue';
import axios from 'axios';
import { getCookie } from '../helpers/cookies';
import PdfViewer from './PdfViewer.vue';



export default {
  emits: ['login', 'logout'],
  props: ['user'],
  components: {
    PopUpComponent,
    ModalPurchaseComponent,
    PdfViewer,
  },
  data() {
    // TODO: dar errores mas especificos dependiendo que pasa, ejemplo signerErrorDuplicate esta sin usarse
    return {
      currentStep: 1,
      loading: false,
      fileNames: [],
      errorMessage: '',
      documentSelected: false,
      signers: [{ name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp' }],
      signerError: false,
      signerErrorMessage: 'Por favor, completa todos los datos de los firmantes.',
      signerErrorDuplicate: 'No puedes agregar dos firmantes con el mismo número de teléfono.',
      maxSignersError: false,
      maxSignersErrorMessage: 'Se ha alcanzado el límite máximo de firmantes (6).',
      documentSigned: false,
      documentId: '',
      automaticPositionChecked: false,
      photoIdChecked: false,
      showInfoMessage: null,
      showInfoMessage2: null,
      showInfoMessageValidacion: null,
      showInfoMessageHojas: null,
      copiedLinks: [],
      base64Doc: null,
      showPopUp: false,
      showLimitExceededModal: false,
      PopUpMessage: 'Por favor corrobore los datos',
      sendWithWapiIABodys: [],
      sendDNI: [false, false, false, false, false, false],
      todasLasSolicitudes: {
        urls: [],
      },
      hasChanges: false,
      campains: [],
      selectedCampainName: '',
      selectedCampaignId: 1,
      send_dni: false,
      validate_dni: false,
      todas_las_hojas: false,
      flow_id: 1,
      isFileUploaded: false, // New property to track file upload status
      isPdfLoaded: false,
      coords: [],
    };
  },
  watch: {
    // Watcher to detect changes in the inputs
    todasLasSolicitudes: {
      handler() {
        if (this.currentStep === 3) {
          this.hasChanges = true;
        }
      },
      deep: true,
    },
    selectedCampainName(newName) {
      this.updateSelectedCampain(newName);
      if (this.validate_dni) {
        this.selectedCampaign.dni_validation = this.validate_dni;
      }
      this.selectedCampaign.todas_las_hojas = this.todas_las_hojas;
    },
    validate_dni(newVal) {
      this.validate_dni = newVal;
      this.selectedCampaign.dni_validation = newVal;
    },
    todas_las_hojas(newVal) {
      this.todas_las_hojas = newVal;
      this.selectedCampaign.todas_las_hojas = newVal;
    },
  },
  methods: {
    // cuando se ponen todos los firmantes, guardo las coordenadas de cada uno
    onCoords(data) {
      this.coords = data;
    },
    onPdfLoaded() {
      this.isPdfLoaded = true; // Set to true when PDF is loaded
    },
    onPdfNotLoaded() {
      this.isPdfLoaded = false; // Set to false when PDF is not loaded
    },
    removeFile(index) {
      this.fileNames.splice(index, 1);
      this.isFileUploaded = false;
      this.base64Doc = null;
      if (this.fileNames.length === 0) {
        this.showPdfViewer = false; // Hide the PDF viewer if no files are left
      }
    },
    logout() {
      this.$emit('logout');
    },
    openFileInput() { // Subir archivos mediante el botón
      document.getElementById('fileInput').click();
    },
    toggleInfo(infoType) { // Click ícono info
      this.showInfoMessage = this.showInfoMessage === infoType ? null : infoType;
      this.showInfoMessageValidacion = this.showInfoMessageValidacion === infoType ? null : infoType;
      this.showInfoMessageHojas = this.showInfoMessageHojas === infoType ? null : infoType;
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    async nextStep() {
      if (this.loading) return; // Prevent multiple clicks

      this.loading = true;

      try {
        // Verificar si todos los campos del firmante están completos o el checkbox tildado
        if (this.currentStep === 1 && !this.documentSigned && this.signers.some(signer => {
          if (signer.contact === 'wpp') {
            return signer.name.trim() === '' || signer.lastName.trim() === '' || String(signer.dni).trim() === '' || String(signer.areaCode).trim() === '' || String(signer.phoneNumber).trim() === '' || signer.contact.trim() === '';
          }
          else if (signer.contact === 'mail') {
            // Verificar si el contacto es por correo electrónico y si el campo de correo electrónico está vacío
            return signer.name.trim() === '' || signer.lastName.trim() === '' || String(signer.dni).trim() === '' || (!signer.email || signer.email.trim() === '' || !signer.email.includes('@')) || signer.contact.trim() === '';
          }
        })) {
          this.signerError = true;
          return; // No avanzar al siguiente paso
        }

        // Verificar si existen dos phoneNumber iguales
        const phoneNumbers = this.signers.map(signer => signer.phoneNumber);
        const uniquePhoneNumbers = new Set(phoneNumbers);
        if (phoneNumbers.length !== uniquePhoneNumbers.size) {
          this.signerError = true;
          return; // No avanzar al siguiente paso debido a números de teléfono duplicados
        }

        // Limpiar msj de error de firmantes al avanzar de paso después de haber ingresado los nombres
        if (this.currentStep === 1) {
          this.signerError = false;
        }

        // Almacenar los datos de los firmantes en una propiedad del componente
        this.signersData = this.signers.map(signer => ({
          signerNumber: this.signers.indexOf(signer) + 1,
          name: signer.name + ' ' + signer.lastName,
          dni: signer.dni,
          email: signer.email,
          phone: `${signer.areaCode}${signer.phoneNumber}`,
          method: signer.contact,
          position_x: null,
          position_y: null,
        }));

        if (this.coords) {
          this.coords.forEach(element => {
            const signer = this.signersData.find(s => s.signerNumber === element.sign.signerNumber);
            if (signer) {
              signer.position_y = element.sign.position_y;
              signer.position_x = element.sign.position_x;
              signer.page = this.todas_las_hojas ? 0 : element.sign.page;
            }
          });
        }

        // HOT FIX: pone el dato phone segun el metodo... ya que la api no acepta parametro de mail.. toma todo como uno 
        for (const element of this.signersData) {
          if (element.method === 'wpp') {
            element.email = null;
          } else {
            element.phone = element.email;
          }
        }

        // Verificar si no se ha seleccionado ningún documento
        if (this.currentStep === 2 && this.fileNames.length === 0) {
          this.errorMessage = 'Seleccione un documento.';
          return;
        }

        if (this.currentStep === 1) {
          await this.getCampains();
        }

        // Crear doc y mostrar popup
        if (this.currentStep === 2) {
          this.loading = true;
          await this.createMission();
          // this is to initialize the sendWithWapiIABodys array
          await this.loadWapia();
        }


        // Si pasa todas las validaciones, avanzar al siguiente paso
        if (!this.showLimitExceededModal) {
          if (this.currentStep === 2) {
            this.showPopUp = true;
            setTimeout(() => {
              this.showPopUp = false;
            }, 5000);
          }
          this.errorMessage = '';
          this.currentStep++;
        }
      } finally {
        this.loading = false;
      }
    },
    sanitizePhoneNumber() {
      // Retain only numbers
      for (const element of this.signers) {
        element.phoneNumber = element.phoneNumber.replace(/\D/g, ""); // Remove everything that's not a digit
      }
    },
    sanitizeDNI(index) {
      let dni = this.signers[index].dni;

      // Extract up to 2 letters at the start
      const letters = dni.match(/^[a-zA-Z]{0,2}/)?.[0] || "";

      // Extract only numbers after the letters
      const numbers = dni.slice(letters.length).replace(/\D/g, "");

      // Combine letters and numbers
      this.signers[index].dni = letters + numbers;
    },

    async loadWapia() {
      if (this.todasLasSolicitudes.urls.length > 0) {

        for (const element of this.todasLasSolicitudes.urls) {

          if (this.send_dni) {
            this.flow_id = 2;
            if (this.selectedCampaign.dni_validation) {
              this.flow_id = 4;
            }
          } else if (this.selectedCampaign.dni_validation) {
            this.flow_id = 3;
          }

          const body = {
            "phone": element.contact,
            "flow_id": this.flow_id,
            "variables": {
              "company": getCookie('token'),
              "name": element.name,
              "sign_link": element.link,
              "document_name": this.documentId ?? 'Documento',
              "document_link": `https://wapi-doc.s3.us-east-2.amazonaws.com/${this.todasLasSolicitudes.id_seguimiento}.pdf`,
              "id_custom": element.id_custom,
              "send_dni": element.send_dni
            }
          };
          this.sendWithWapiIABodys.push(body);
        }
      }
    },
    handleCheckboxChange(signer) {
      // Verificar si el checkbox está tildado
      if (signer.sendWithWapiIA) {
        if (this.documentId === '') {
          this.documentId = 'Documento';
        }

        if (this.send_dni) {
          this.flow_id = 2;
          if (this.selectedCampaign.dni_validation) {
            this.flow_id = 4;
          }
        } else if (this.selectedCampaign.dni_validation) {
          this.flow_id = 3;
        }

        const body = {
          "phone": signer.contact,
          "flow_id": this.flow_id,
          "variables": {
            "company": getCookie('token'),
            "name": signer.name,
            "sign_link": signer.link,
            "document_name": this.documentId ?? 'Documento',
            "document_link": `https://wapi-doc.s3.us-east-2.amazonaws.com/${this.todasLasSolicitudes.id_seguimiento}.pdf`,
            "id_custom": signer.id_custom,
            "send_dni": signer.send_dni
          }
        }
        this.sendWithWapiIABodys.push(body);
      } else {
        // deletes the body from the array
        for (let i = 0; i < this.sendWithWapiIABodys.length; i++) {
          if (this.sendWithWapiIABodys[i].phone === signer.contact) {
            this.sendWithWapiIABodys.splice(i, 1);
          }
        }
      }
    },
    updateSelectedCampain(selectedName) {
      this.selectedCampaign = this.campains.find(item => item.name === selectedName) || null;
      this.selectedCampaignId = this.selectedCampaign?.id ?? 0;
      if (this.selectedCampaignId === 1) {
        this.isPdfLoaded = false;
      } else {
        this.isPdfLoaded = true;
      }
    },
    async getCampains() {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/campains`, { headers });
      this.campains = response.data;
      if (this.campains.length > 0) {
        this.selectedCampainName = this.campains[1].name;
        this.selectedCampaignId = this.campains[1].id;
        if (this.selectedCampaignId !== 1) {
          this.isPdfLoaded = true;
        }
      }
    },
    async sendDocuments() {
      let counter = 0;
      for (let body of this.sendWithWapiIABodys) {
        let data = JSON.stringify(body);
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://api.wapifirma.com/wpp/sendFlow`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        if (counter > 0 && this.selectedCampaignId === 1) {
          return;
        } else {
          await axios.request(config);
          counter++;
        }
      }
    },
    async updateMission() {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      const body = {
        datos_firmantes: this.todasLasSolicitudes.urls,
      };

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/modifySignatureRequest`, body, { headers });
        return response.data;
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    },
    async updateData() { // Datos actualizados de los input    
      // Mostrar popup
      this.PopUpMessage = 'Datos actualizados';
      this.showPopUp = true;
      this.hasChanges = false;


      await this.updateMission();
      setTimeout(() => {
        this.showPopUp = false;
      }, 5000);

    },
    async createMission() {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      if (this.selectedCampainName === '') {
        this.errorMessage = 'Seleccione una posición.';
        return;
      }

      // TODO: elegir mejor el flow_id... que se actualize cuando se marque
      if (this.send_dni) {
        this.flow_id = 2;
        if (this.selectedCampaign.dni_validation) {
          this.flow_id = 4;
        }
      } else if (this.selectedCampaign.dni_validation) {
        this.flow_id = 3;
      }

      // TODO: arreglar el client name
      const body = {
        datos_firmantes: this.signersData,
        id_custom_client: this.documentId ?? null,
        document: this.base64Doc,
        webhook_url: null,
        cliente: 'luis',
        fixed_signature: this.selectedCampaign,
        flow_id: this.flow_id,
        firma_en_todas_las_hojas: this.todas_las_hojas,
      };

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/signatureRequest`, body, { headers });
        this.todasLasSolicitudes = response.data;

        for (let element of this.todasLasSolicitudes.urls) {
          element.sendWithWapiIA = true;
          element.send_dni = this.send_dni;
        }
        return this.todasLasSolicitudes;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          // Si la solicitud falla debido a "Demasiadas solicitudes", muestra la modal
          this.showLimitExceededModal = true;
          return this.showLimitExceededModal;
        } else {
          // Manejar otros errores de manera diferente, si es necesario
          console.error('Error en la solicitud:', error);
        }
      }
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      const file = files[0]; // Obtener el primer archivo arrastrado
      this.handleFile(file);
      this.documentId = this.removeFileExtension(file.name);
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // Obtener el primer archivo del evento
      this.handleFile(file);
      if (!this.documentId) { // Si no se ingresó un nombre de documento, se autocompleta
        this.documentId = this.removeFileExtension(file.name);
      }
    },
    removeFileExtension(filename) {
      return filename.replace(/\.[^/.]+$/, ""); // Eliminar la extensión del nombre del archivo
    },
    handleFile(file) {
      this.loading = true;
      this.errorMessage = '';
      this.documentSelected = false;

      if (this.isValidFileType(file)) {
        let reader = new FileReader();

        reader.onload = () => {

          setTimeout(() => {
            this.base64Doc = reader.result.split(',')[1]; // Extracting base64 data from result

            this.fileNames = [file.name]; // Reemplazar los nombres de archivo con el nuevo archivo
            this.loading = false;
            this.documentSelected = true;
            this.isFileUploaded = true;
          }, 1000); // Tiempo de simulación de carga
        };
        reader.readAsDataURL(file);
      } else {
        this.errorMessage = 'Solo archivos PDF';
        this.loading = false;
      }
    },
    removeErrorMessage(errorType) {
      if (errorType === 'errorMessage') {
        this.errorMessage = '';
      }
    },
    isValidFileType(file) {
      // TODO: , '.docx', '.jpg', '.jpeg', '.png'
      const allowedExtensions = ['.pdf'];
      const fileType = '.' + file.name.split('.').pop();
      return allowedExtensions.includes(fileType.toLowerCase());
    },
    addSigner() {
      if (this.signers.length < 6) {
        this.signers.push({ name: '', lastName: '', dni: '', areaCode: '', phoneNumber: '', contact: 'wpp' }); // Agregar un nuevo objeto de firmante con campos vacíos
      } else {
        this.maxSignersError = true; // Mostrar el mensaje de error
      }
    },
    removeSigner(index) {
      // Verificar si hay más de un firmante antes de eliminar
      if (this.signers.length > 1) {
        this.signers.splice(index, 1); // Eliminar el firmante en la posición especificada
      } else {
        // Limpiar los campos del primer firmante en lugar de eliminarlo
        this.signers[index].name = '';
        this.signers[index].lastName = '';
        this.signers[index].dni = '';
        this.signers[index].areaCode = '';
        this.signers[index].phoneNumber = '';
        this.signers[index].contact = '';
      }
    },
    copyLink(link) { // Método para copiar el link
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copiedLinks.push(link);

      setTimeout(() => { // Eliminar el enlace copiado después de dos segundos
        const index = this.copiedLinks.indexOf(link);
        if (index !== -1) {
          this.copiedLinks.splice(index, 1);
        }
      }, 2000);
    },
  }

}
</script>

<style>
.cursor-not-allowed {
  cursor: not-allowed;
}

.step-container {
  padding: 1rem;
}

.step-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-text {
  border-bottom: 1px solid #ccc;
  margin-right: 1rem;
  padding: 0.5rem;
  width: 16rem;
}

.button-group {
  display: flex;
  align-items: center;
}

.btn-back,
.btn-next {
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.btn-back {
  color: #1d4ed8;
  margin-right: 1rem;
}

.icon-back {
  margin-right: 0.5rem;
  background-color: #1d4ed8;
  color: white;
  padding: 0.25rem;
  border-radius: 50%;
}

.btn-next {
  color: #1d4ed8;
}

.icon-next {
  margin-left: 0.5rem;
  background-color: #1d4ed8;
  color: white;
  padding: 0.25rem;
  border-radius: 50%;
}

.error-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin: 1rem 0;
}

.error-text {
  font-weight: bold;
}

.error-close {
  background: none;
  border: none;
  cursor: pointer;
}

.signature-position {
  margin-left: 1.5rem;
}

.signature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.select-group {
  display: flex;
  align-items: center;
}

.select-input {
  border-bottom: 2px solid #ccc;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.info-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}

.info-message {
  position: absolute;
  background-color: #e5e7eb;
  padding: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dni-request {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.checkbox {
  margin-right: 0.5rem;
}

.file-upload {
  margin: 1.5rem;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  border: 4px dashed #3b82f6;
  cursor: pointer;
}

.file-icon {
  color: #9ca3af;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.file-text {
  color: #9ca3af;
}

.file-button {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.loading-message {
  text-align: center;
  margin: 1.5rem;
}

.loading-bar {
  width: 6rem;
  height: 0.25rem;
  background-color: #3b82f6;
  border-radius: 0.25rem;
  animation: pulse 1s infinite;
}

.loading-text {
  color: #6b7280;
  margin-top: 0.5rem;
}

.file-list {
  margin: 1.5rem;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #bfdbfe;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.file-name {
  color: black;
}

.file-remove {
  background: none;
  border: none;
  cursor: pointer;
}

.pdf-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>