<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center z-50">
    <div class="fixed inset-0 bg-black opacity-50"></div>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-1/3">
      <h3 class="text-xl font-bold mb-4">¡Bienvenido a Wapi Firma! 🚀</h3>
      <p class="mb-4">Para comenzar, te recomendamos ver nuestros videos tutoriales.</p>

      <button @click="goToVideos" class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md">
        Ver videos
      </button>
      <button @click="close" class="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md ml-3">
        Cerrar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isVisible'],
  methods: {
    close() {
      this.$emit('close');
    },
    goToVideos() {
      // Open YouTube playlist in a new tab
      window.open(
        'https://www.youtube.com/playlist?list=PLgWZs8WWi49xSfqH-g-06omGM8ZwCiP2K',
        '_blank'
      );
      this.close(); // Close the modal after navigating (optional)
    }
  }
}
</script>
